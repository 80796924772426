import React, { useRef, useLayoutEffect } from "react"
import { Helmet } from "react-helmet"
import { services } from "../../utils/constants"
import { ServiceCard } from "../../components/ServiceCard"
import { gsap } from "gsap"
import SplitType from "split-type"

const index = () => {
	const container = useRef(null)
	useLayoutEffect(() => {
		let ctx = gsap.context(() => {
			const cards = gsap.utils.toArray(".serviceCard")

			const splitTitleParent = new SplitType("#servicesTitle", {
				types: "lines",
				lineClass: "lineParentsServicesTitle",
			})
			const splitTitle = new SplitType(".lineParentsServicesTitle", {
				types: "lines",
			})

			const tl = gsap.timeline({ defaults: { ease: "Power4.easeOut" } })

			tl.fromTo(
				splitTitle.lines,
				{
					y: 100,
					opacity: 0,
				},
				{
					y: 0,
					opacity: 1,
					duration: 1,
					delay: 0.2,
					stagger: 0.2,
				}
			)
			tl.fromTo(
				cards,
				{
					y: 50,
					opacity: 0,
				},
				{
					y: 0,
					opacity: 1,
					duration: 0.5,
					stagger: 0.1,
				},
				"-=0.5"
			)
		}, container)

		return () => ctx.revert()
	}, [])

	return (
		<section
			className="w-full h-full flex flex-col gap-[80px] md:gap-[100px] p-phone md:p-tablet lg:px-desktop lg:py-tablet"
			ref={container}
		>
			<Helmet>
				<title>Services | Amazing Pet Grooming</title>
				<meta
					name="description"
					content="We offer a range of services to keep your pet looking and feeling their best."
				/>
				<link
					rel="canonical"
					href="https://www.amazingpetgrooming.ca/services"
				/>
			</Helmet>
			<main className="w-full h-full bg-white flex flex-col gap-[50px]">
				<h4
					className="text-main text-[32px] leading-[50px] pt-12 md:text-[55px] md:leading-[70px] font-black text-center"
					id="servicesTitle"
				>
					Grooming
				</h4>
				<div className="w-full h-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[50px] triggerContainer">
					{services.map((service) => (
						<ServiceCard
							service={service}
							key={service.name}
							className="serviceCard opacity-0"
							oppositeColor
						/>
					))}
				</div>
			</main>
		</section>
	)
}

export default index
