import React, { useLayoutEffect, useRef } from "react"
import { Helmet } from "react-helmet"
import ImageInText from "../../components/ImageInText"
import TeamMemberCard from "../../components/TeamMemberCard"
import { teamMembers } from "../../utils/constants"
import { gsap, Power4 } from "gsap"
import SplitType from "split-type"

const index = () => {
	const container = useRef(null)
	useLayoutEffect(() => {
		let ctx = gsap.context(() => {
			const tl = gsap.timeline({ defaults: { ease: Power4.easeOut } })

			const splitTitle = new SplitType(".lineParentAboutTitle", {
				types: "lines",
			})

			const splitMeetTeamTitle = new SplitType(".lineParentMeetTeamTitle", {
				types: "lines",
			})

			tl.fromTo(
				splitTitle.lines,
				{
					y: 100,
					opacity: 0,
				},
				{
					y: 0,
					opacity: 1,
					duration: 1,
					delay: 0.2,
					stagger: 0.2,
				}
			)
			tl.fromTo(
				".aboutText",
				{
					y: 50,
					opacity: 0,
				},
				{
					y: 0,
					opacity: 1,
					duration: 1,
				},
				"-=0.5"
			)
			tl.fromTo(
				splitMeetTeamTitle.lines,
				{
					y: 50,
					opacity: 0,
				},
				{
					y: 0,
					opacity: 1,
					duration: 1,
					stagger: 0.2,
				},
				"-=0.7"
			)

			const tlScroll = gsap.timeline({
				scrollTrigger: {
					trigger: ".trigger",
					start: "top 80%",
				},
			})

			tlScroll.fromTo(
				".teamMemberCard",
				{
					y: 50,
					opacity: 0,
				},
				{
					y: 0,
					opacity: 1,
					duration: 0.7,
					stagger: 0.2,
				}
			)
		}, container)

		return () => ctx.revert()
	}, [])

	return (
		<section
			className="w-full container h-full flex flex-col gap-[10px] md:gap-[10px] p-phone md:p-tablet lg:px-desktop lg:py-tablet"
			ref={container}
		>
			<Helmet>
				<title>About Us | Amazing Pet Grooming</title>
				<meta
					name="description"
					content="With more than 22 years of experience, you can be sure your dog or cat is in the best hands."
				/>
				<link rel="canonical" href="https://www.amazingpetgrooming.ca/about" />
			</Helmet>
			<main className="w-full h-full bg-white flex flex-col items-center px-4 md:px-8 lg:px-16 py-12 gap-8">
				<h4
					className="text-main aboutTitle text-[32px] md:text-[50px] lg:text-[70px] font-black text-center leading-tight"
					id="aboutTitle"
				>
					About Us
				</h4>
				<p className="text-main aboutText text-[18px] md:text-[22px] lg:text-[26px] leading-relaxed text-justify max-w-5xl">
					With more than 30 years of experience in the animal care field, you
					can rest assured that your dogs and cats are in the best hands. Our
					open-concept design allows you to watch your dog or cat being
					pampered, giving you peace of mind. Our environment provides your pets
					with a unique and stress-free experience—one that sets us apart from
					other pet groomers. Your pets will be just as happy coming in as they
					are going home.
					<br />
					<br />
					Additionally, our doggie daycare services are designed to create a fun
					and stimulating environment for your furry friends. Our dedicated
					staff understands the importance of socialization and exercise for
					dogs, ensuring that your pets have a blast interacting with other dogs
					in a safe, supervised setting. We offer a wide range of activities and
					play areas, keeping your dogs engaged and active throughout the day.
				</p>
			</main>

			<section className="w-full h-full flex flex-col gap-[40px] md:gap-[60px]">
				<h4
					className="text-main aboutText text-[30px] md:text-[45px] font-black text-center"
					id="meetTeamTitle"
				>
					Meet the Team
				</h4>
				<div className="w-full h-full grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 justify-items-center">
					{Object.values(teamMembers).map((member) =>
						member.description ? (
							<TeamMemberCard member={member} key={member.name} />
						) : null
					)}
				</div>
			</section>
		</section>
	)
}

export default index
